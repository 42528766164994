import { Auth } from 'aws-amplify'

async function getUser() {
  try {
    const user = await Auth.currentAuthenticatedUser()
    if (user && user.signInUserSession) {
      const { attributes, ...rest } = user

      const updatedUser = Object.keys(attributes).reduce(
        (acc, key) => {
          if (key === 'custom:region') {
            acc.regions = attributes[key] ? JSON.parse(attributes[key]) : []
          } else {
            acc[key] = attributes[key]
          }
          return acc
        },
        { ...rest }
      )
      updatedUser.roles = user.signInUserSession.accessToken.payload['cognito:groups']
      updatedUser.regions = updatedUser.regions || []
      delete updatedUser.attributes
      delete updatedUser.pool
      delete updatedUser.Session
      delete updatedUser.client
      delete updatedUser.signInUserSession
      delete updatedUser.authenticationFlowType
      delete updatedUser.keyPrefix
      delete updatedUser.userDataKey
      delete updatedUser.storage
      delete updatedUser.preferredMFA
      delete updatedUser.sub
      return updatedUser
    } else {
      return null
    }
  } catch (err) {
    console.error(err)
    return null
  }
}

async function getGroups() {
  return Auth.currentAuthenticatedUser()
    .then((user) => {
      if (user && user.signInUserSession) {
        return user.signInUserSession.accessToken.payload['cognito:groups']
      } else {
        return null
      }
    })
    .catch((err) => {
      // eslint-disable-next-line
      console.log(err)
    })
}

async function getToken() {
  try {
    const session = await Auth.currentSession()
    return session ? session.idToken.jwtToken : null
  } catch (err) {
    console.error(err)
    throw err
  }
}

async function signUp(username, password) {
  try {
    const res = await Auth.signUp({
      username,
      password,
      attributes: {
        email: username
      }
    })
    console.log(res, '1234567890-')
    return res
  } catch (err) {
    console.error(err)
    throw err
  }
}

async function confirmSignUp(username, code) {
  try {
    const res = await Auth.confirmSignUp(username, code)
    return res
  } catch (err) {
    console.error(err)
    throw err
  }
}

async function resendSignUp(username) {
  try {
    const res = await Auth.resendSignUp(username)
    return res
  } catch (err) {
    console.error(err)
    throw err
  }
}

async function signIn(username, password) {
  try {
    await Auth.signIn(username, password)
    return { status: 'success' }
  } catch (err) {
    if (err.code === 'UserNotConfirmedException') {
      console.log(err)
    } else if (err.code === 'PasswordResetRequiredException') {
      console.log(err)
    } else if (err.code === 'NotAuthorizedException') {
      console.log(err)
    } else if (err.code === 'UserNotFoundException') {
      console.log(err)
    } else {
      console.log(err)
    }
    return { status: 'unSuccess' }
  }
}

async function signOut() {
  try {
    const res = await Auth.signOut()
    window.location.reload()
    return res
  } catch (err) {
    console.error(err)
    throw err
  }
}
async function changePassword(oldPassword, newPassword) {
  try {
    const currentUser = await Auth.currentAuthenticatedUser()
    const res = await Auth.changePassword(currentUser, oldPassword, newPassword)
    return res
  } catch (err) {
    console.error(err)
    throw err
  }
}
export {
  getUser,
  getGroups,
  signUp,
  confirmSignUp,
  resendSignUp,
  signIn,
  signOut,
  getToken,
  changePassword
}
