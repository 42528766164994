import { createRouter, createWebHistory } from 'vue-router'
import { getUser } from '@/helpers/auth.js'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'MainLayout',
      component: () => import('@/layout/LayoutComponent.vue'),
      redirect: '/main',
      children: [
        {
          path: '/main',
          name: 'main',
          component: () => import('../views/partner/MainView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['PARTNER:SHOW', 'PARTNER:EDIT', 'PARTNER:FULL_ACCESS']
          }
        },
        {
          path: '/newPartner',
          name: 'newPartner',
          component: () => import('../views/partner/NewPartnerRequest.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['PARTNER:SHOW', 'PARTNER:EDIT', 'PARTNER:FULL_ACCESS']
          }
        },
        {
          path: '/partner-info',
          component: () => import('../layout/AddPartnerLayout.vue'),
          children: [
            {
              path: '/partner-info',
              name: 'AddPartner',
              component: () => import('../views/partner/AddPartner.vue'),
              meta: {
                permissions: ['PARTNER:SHOW', 'PARTNER:EDIT', 'PARTNER:FULL_ACCESS']
              }
            },
            {
              path: '/partner-address-hexagon',
              name: 'AddPartnerAddressHexagon',
              component: () => import('../views/partner/AddPartnerAddressHexagon.vue'),
              meta: {
                permissions: ['PARTNER:SHOW', 'PARTNER:EDIT', 'PARTNER:FULL_ACCESS']
              }
            },
            {
              path: '/partner-timetable',
              name: 'AddPartnerTimetable',
              component: () => import('../views/partner/AddPartnerTimetable.vue'),
              meta: {
                permissions: ['PARTNER:SHOW', 'PARTNER:EDIT', 'PARTNER:FULL_ACCESS']
              }
            },
            {
              path: '/partner-additional-information',
              name: 'AddAdditionalInformation',
              component: () => import('../views/partner/AddAdditionalInformation.vue'),
              meta: {
                permissions: ['PARTNER:SHOW', 'PARTNER:EDIT', 'PARTNER:FULL_ACCESS']
              }
            },
            {
              path: '/contractInfo',
              name: 'ContractInfo',
              component: () => import('../views/partner/ContractInfo.vue'),
              meta: {
                permissions: ['PARTNER:SHOW', 'PARTNER:EDIT', 'PARTNER:FULL_ACCESS']
              }
            },
            {
              path: '/partner-additional',
              name: 'AddPartnerAdditional',
              component: () => import('../views/partner/AddPartnerAdditional.vue'),
              meta: {
                permissions: ['PARTNER:SHOW', 'PARTNER:EDIT', 'PARTNER:FULL_ACCESS']
              }
            },
            {
              path: '/cognitoInfo',
              name: 'cognitoInfo',
              component: () => import('../views/partner/cognitoInfo.vue'),
              meta: {
                permissions: ['PARTNER:SHOW', 'PARTNER:EDIT', 'PARTNER:FULL_ACCESS']
              }
            }
          ]
        },
        {
          path: '/cognitoClientList',
          name: 'cognitoClientList',
          component: () => import('../views/partner/CognitoClientList.vue'),
          meta: {
            permissions: ['PARTNER:SHOW', 'PARTNER:EDIT', 'PARTNER:FULL_ACCESS'],
            requiresAuth: true
          }
        },
        {
          path: '/noSalesPartner',
          name: 'noSalesPartner',
          component: () => import('../views/partner/NoSalesPartner.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['PARTNER:SHOW', 'PARTNER:EDIT', 'PARTNER:FULL_ACCESS']
          }
        },
        {
          path: '/cognitoCcList',
          name: 'cognitoCcList',
          component: () => import('../views/partner/CognitoCcList.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['PARTNER:SHOW', 'PARTNER:EDIT', 'PARTNER:FULL_ACCESS']
          }
        },
        {
          path: '/reportDashboard',
          name: 'reportDashboard',
          component: () => import('../views/dashboard/report/MainView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['REPORT_DASHBOARD:SHOW', 'REPORT_DASHBOARD:DOWNLOAD']
          }
        },
        {
          path: '/clientDashboard',
          name: 'clientDashboard',
          component: () => import('../views/dashboard/client/MainView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['CLIENT_DASHBOARD:SHOW']
          }
        },
        {
          path: '/collection-fee-config',
          name: 'CollectionFeeConfig',
          component: () => import('../views/collectionFee/MainView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['DELIVERY_CHARGE:SHOW', 'DELIVERY_CHARGE:EDIT']
          }
        },
        {
          path: '/suggest-menu',
          name: 'SuggestMenu',
          component: () => import('../views/suggestMenu/MainView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['APP_CONFIG:SHOW', 'APP_CONFIG:EDIT']
          }
        },
        {
          path: '/menu',
          name: 'menu',
          component: () => import('../views/menu/ListMenu.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['PARTNER:SHOW', 'PARTNER:EDIT']
          }
        },
        {
          path: '/edit-menu',
          name: 'EditMenu',
          component: () => import('../views/menu/EditMenu.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['PARTNER:SHOW', 'PARTNER:EDIT']
          }
        },
        {
          path: '/timeLimit-banner',
          name: 'TimeLimitBanner',
          component: () => import('../views/timeLimitBanner/MainView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['APP_CONFIG:SHOW', 'APP_CONFIG:EDIT']
          }
        },
        {
          path: '/delivery-charge',
          name: 'DeliveyCharge',
          component: () => import('../views/deliveyCharge/MainView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['DELIVERY_CHARGE:SHOW', 'DELIVERY_CHARGE:EDIT']
          }
        },
        {
          path: '/banner',
          name: 'banner',
          component: () => import('../views/banner/MainView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['APP_CONFIG:SHOW', 'APP_CONFIG:EDIT']
          }
        },
        {
          path: 'peak-hour',
          name: 'PeakHour',
          component: () => import('../views/peakHour/MainView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['DELIVERY_CHARGE:SHOW', 'DELIVERY_CHARGE:EDIT']
          }
        },
        {
          path: 'set-time-product',
          name: 'setTimeProduct',
          component: () => import('../views/setTimeProduct/MainView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['APP_CONFIG:SHOW', 'APP_CONFIG:EDIT']
          }
        },
        {
          path: 'rider-list',
          name: 'riderList',
          component: () => import('../views/riderList/MainView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['DRIVER:SHOW', 'DRIVER:EDIT']
          }
        },
        {
          path: 'rider-team-list',
          name: 'riderTeamList',
          component: () => import('../views/riderTeam/MainView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['DRIVER:SHOW', 'DRIVER:EDIT']
          }
        },
        {
          path: 'rider-time-schedule',
          name: 'riderTimeSchedule',
          component: () => import('../views/riderTimeSchedule/MainView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['DRIVER:SHOW', 'DRIVER:EDIT']
          }
        },
        {
          path: 'tpr-delivery-price',
          name: 'tprDeliveryPrice',
          component: () => import('../views/tprDeliveryPrice/MainView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['DRIVER_BONUS:SHOW', 'DRIVER_BONUS:EDIT']
          }
        },
        {
          path: 'tpr-delivery-price-detail',
          name: 'tprDeliveryPriceDetail',
          component: () => import('../views/tprDeliveryPrice/DetailView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['DRIVER_BONUS:SHOW', 'DRIVER_BONUS:EDIT']
          }
        },
        {
          path: '/service',
          name: 'service',
          component: () => import('../views/service/MainView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['APP_CONFIG:SHOW', 'APP_CONFIG:EDIT']
          }
        },
        {
          path: '/categories',
          name: 'categories',
          component: () => import('../views/categories/MainView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['APP_CONFIG:SHOW', 'APP_CONFIG:EDIT']
          }
        },
        {
          path: '/menuCategories',
          name: 'menuCategories',
          component: () => import('../views/menuCategories/MainView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['APP_CONFIG:SHOW', 'APP_CONFIG:EDIT']
          }
        },
        {
          path: '/badge',
          component: () => import('../views/badge/MainView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['APP_CONFIG:SHOW', 'APP_CONFIG:EDIT']
          }
        },
        {
          path: '/tpr-config',
          name: 'tprConfig',
          component: () => import('../views/tprConfig/MainView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['TPR_LIMIT:SHOW', 'TPR_LIMIT:EDIT']
          }
        },
        {
          path: '/tpr-config-add',
          component: () => import('../views/tprConfig/TprConfigAddEdit.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['TPR_LIMIT:SHOW', 'TPR_LIMIT:EDIT']
          }
        },
        {
          path: '/tpr-config-history',
          name: 'tprConfigHistory',
          component: () => import('../views/tprConfig/TprConfigHistory.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['TPR_LIMIT:SHOW', 'TPR_LIMIT:EDIT']
          }
        },
        {
          path: '/story',
          name: 'story',
          component: () => import('../views/story/StoryView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['APP_CONFIG:SHOW', 'APP_CONFIG:EDIT']
          }
        },
        {
          path: '/Marketing',
          name: 'Marketing',
          component: () => import('../views/marketing/MarketingView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['APP_CONFIG:SHOW', 'APP_CONFIG:EDIT']
          }
        },
        {
          path: '/ihihBonus',
          name: 'ihihBonus',
          component: () => import('../views/ihihBonus/MainScreen.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['DRIVER_BONUS:SHOW', 'DRIVER_BONUS:EDIT']
          }
        },
        {
          path: '/latePayStep',
          name: 'latePayStep',
          component: () => import('../views/latepay/LatePayView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['FINTECH:SHOW', 'FINTECH:EDIT', 'FINTECH:FULL_ACCESS']
          }
        },
        {
          path: '/addressRequest',
          name: 'addressRequest',
          component: () => import('../views/address/addressRequest/MainView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['ADDRESS:FULL_ACCESS']
          }
        },
        {
          path: '/editAddress',
          name: 'editAddress',
          component: () => import('../views/address/editAddress/EditAddressView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['ADDRESS:SHOW', 'ADDRESS:EDIT', 'ADDRESS:FULL_ACCESS']
          }
        },
        {
          path: '/searchAddress',
          name: 'searchAddress',
          component: () => import('../views/address/editAddress/SearchAddressView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['ADDRESS:SHOW', 'ADDRESS:EDIT', 'ADDRESS:FULL_ACCESS']
          }
        },
        {
          path: '/showLocation',
          name: 'showLocation',
          component: () => import('../views/address/client/ShowLocation.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['PARTNER:SHOW', 'PARTNER:EDIT']
          }
        },
        {
          path: '/regionList',
          name: 'regionList',
          component: () => import('../views/address/regionView/MainView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['APP_CONFIG:SHOW', 'APP_CONFIG:EDIT']
          }
        },
        {
          path: '/kitchenBanner',
          name: 'kitchenBanner',
          component: () => import('../views/kitchen/KitchenBannerView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['ORDER_CONFIG:SHOW', 'ORDER_CONFIG:EDIT']
          }
        },
        {
          path: '/kitchenBannerTime',
          name: 'kitchenBannerTime',
          component: () => import('../views/kitchen/KitchenTimeView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['ORDER_CONFIG:SHOW', 'ORDER_CONFIG:EDIT']
          }
        },
        {
          path: '/galGalBonus',
          name: 'galGalBonus',
          component: () => import('../views/galGalBonus/MainView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['DRIVER_BONUS:SHOW', 'DRIVER_BONUS:EDIT']
          }
        },
        {
          path: '/galGalBonusUpdate',
          name: 'galGalBonusUpdate',
          component: () => import('../views/galGalBonus/GalgalUpdate.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['DRIVER_BONUS:SHOW', 'DRIVER_BONUS:EDIT']
          }
        },
        {
          path: '/galGalBonusAdd',
          name: 'galGalBonusAdd',
          component: () => import('../views/galGalBonus/GalGalAdd.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['DRIVER_BONUS:SHOW', 'DRIVER_BONUS:EDIT']
          }
        },
        {
          path: '/scheduleTimeBonus',
          name: 'scheduleTimeBonus',
          component: () => import('../views/scheduleTimeBonus/MainView.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['DRIVER_BONUS:SHOW', 'DRIVER_BONUS:EDIT']
          }
        },
        {
          path: '/scheduleTimeBonusAdd',
          name: 'scheduleTimeBonusAdd',
          component: () => import('../views/scheduleTimeBonus/ScheduleTimeBonusAdd.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['DRIVER_BONUS:SHOW', 'DRIVER_BONUS:EDIT']
          }
        },
        {
          path: '/scheduleTimeBonusUpdate',
          name: 'scheduleTimeBonusUpdate',
          component: () => import('../views/scheduleTimeBonus/ScheduleTimeBonusUpdate.vue'),
          meta: {
            requiresAuth: true,
            permissions: ['DRIVER_BONUS:SHOW', 'DRIVER_BONUS:EDIT']
          }
        },
        {
          path: '/about',
          name: 'about',
          component: () => import('../views/about/AboutView.vue'),
          meta: { requiresAuth: true }
        },
        {
          path: '/404',
          name: '404',
          component: () => import('@/views/404/NotFound.vue')
        },
        {
          path: '/403',
          name: '403',
          component: () => import('@/views/403/ForbiddenView.vue')
        }
      ]
    },
    { path: '/:catchAll(.*)', redirect: { name: '404' } },
    {
      path: '/signIn',
      name: 'signIn',
      component: () => import('@/views/login/LoginView.vue')
    },
    {
      path: '/signUp',
      name: 'signUp',
      component: () => import('@/views/login/SignUpView.vue')
    }
  ]
})
router.beforeResolve(async (to, from, next) => {
  try {
    const user = await getUser()
    const permissions = user?.roles || []

    if (!user) {
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        return next({ path: '/signIn' })
      }
      if (to.matched.some((record) => record.path === '/404' || record.path === '/403')) {
        return next({ path: '/signIn' })
      }
    } else {
      if (to.matched.some((record) => record.path === '/404' || record.path === '/403')) {
        return next()
      }
      if (
        to.matched.some(
          (record) =>
            record.meta?.permissions &&
            !record.meta.permissions.some((permission) => permissions.includes(permission))
        )
      ) {
        return next({ path: '/403' })
      }
      if (
        to.matched.some(
          (record) => typeof record.meta.requiresAuth === 'boolean' && !record.meta.requiresAuth
        )
      ) {
        return next({ path: '/' })
      }
    }
    return next()
  } catch (error) {
    console.error('Error in router beforeResolve:', error)
  }
})

router.onError((error, to) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed') ||
    error.message.includes('Loading chunk')
  ) {
    if (!to?.fullPath) {
      window.location.reload()
    } else {
      window.location = to.fullPath
    }
  }
})

export default router
