import axios from 'axios'
import { getToken } from './auth'
import { showMessage } from './helper'
import { has } from 'lodash'
import { ElNotification } from 'element-plus'

const instance = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: `${import.meta.env.VITE_APP_ADMIN_API}`,
  timeout: 30000
})
// const  globalState = use// globalState()
instance.interceptors.request.use(
  async (config) => {
    if (
      !Object.prototype.hasOwnProperty.call(config.headers, 'Authorization') ||
      !config.headers.Authorization
    ) {
      config.headers.Authorization = `Bearer ${await getToken()}`
    }
    return config
  },
  (error) => {
    console.log('🚀 ~ file: request.js:21 ~ error:', error)
    return Promise.reject(error)
  }
)
instance.interceptors.response.use(
  (response) => {
    if (has(response.data, 'message') && response.data.message !== '')
      showMessage(response?.status === 'success' ? 'success' : 'warning', response.data.message)
    return response.data
  },
  (error) => {
    let message = error.message
    if (
      has(error, 'response') &&
      has(error.response, 'data') &&
      has(error.response.data, 'message')
    ) {
      message = error.response.data.message
    }
    ElNotification.error({
      title: message
    })
    return Promise.reject(error)
  }
)
export default instance
