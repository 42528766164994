import './index.css'

import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import request from '@/helpers/request'
import { showMessage, showNotif } from './helpers/helper'

import 'leaflet/dist/leaflet.css'
import { LMap, LTileLayer, LMarker, LPolyline } from '@vue-leaflet/vue-leaflet'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import MN from 'element-plus/dist/locale/mn.mjs'
import 'element-plus/dist/index.css'
import * as Sentry from '@sentry/vue'

import { Amplify } from 'aws-amplify'
import awsconfig from './aws-exports'
import VueApexCharts from 'vue3-apexcharts'
Amplify.configure(awsconfig)

const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.component('l-map', LMap)
app.component('l-tile-layer', LTileLayer)
app.component('l-marker', LMarker)
app.component('l-polyline', LPolyline)

app.use(ElementPlus, { locale: MN })
app.use(router)

app.provide('api', request)
app.provide('showMessage', showMessage)
app.provide('showNotif', showNotif)
app.use(VueApexCharts)
app.provide('apexchart', VueApexCharts)

Sentry.init({
  app,
  dsn: 'https://8724fbc17c9da8cf8253aca7395b56b8@o4507061686435840.ingest.us.sentry.io/4507061698101248',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

app.mount('#app')
